import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { AuthenticationService } from './services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
    private sub: any;

    constructor(private slimLoader: SlimLoadingBarService, private router: Router,       
         private authenticationService: AuthenticationService, private translate: TranslateService
        ) {
            translate.addLangs(['en', 'fr']);
            translate.setDefaultLang('fr');

            const browserLang: string = translate.getBrowserLang();
            translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
            // Listen the navigation events to start or complete the slim bar loading
            this.sub = this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    this.slimLoader.start();
                //  if(!localStorage.getItem('id')){
                    //  this.router.navigate(['/authentication/login']);
                
                    //}
                } else if (event instanceof NavigationEnd ||
                    event instanceof NavigationCancel ||
                    event instanceof NavigationError) {
                    this.slimLoader.complete();
                }
            }, (error: any) => {
                this.slimLoader.complete();
            });
    }

    ngOnDestroy(): any {
        this.sub.unsubscribe();
    }
}
