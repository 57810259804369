import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedService } from '../shared.service';
import { Router } from '@angular/router';
declare var $: any;

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  currentUserInfo: any;
  lang: any = 'FR';
  constructor(private modalService: NgbModal, private router: Router,
     private sharedService: SharedService, private translateService: TranslateService ) {
    this.currentUserInfo= JSON.parse(localStorage.getItem('currentUser'));
  }

  // This is for Notifications
  // notifications: Object[] = [
  //   {
  //     btn: 'btn-danger',
  //     icon: 'ti-link',
  //     title: 'Luanch Admin',
  //     subject: 'Just see the my new admin!',
  //     time: '9:30 AM'
  //   },
  //   {
  //     btn: 'btn-success',
  //     icon: 'ti-calendar',
  //     title: 'Event today',
  //     subject: 'Just a reminder that you have event',
  //     time: '9:10 AM'
  //   },
  //   {
  //     btn: 'btn-info',
  //     icon: 'ti-settings',
  //     title: 'Settings',
  //     subject: 'You can customize this template as you want',
  //     time: '9:08 AM'
  //   },
  //   {
  //     btn: 'btn-primary',
  //     icon: 'ti-user',
  //     title: 'Pavan kumar',
  //     subject: 'Just see the my admin!',
  //     time: '9:00 AM'
  //   }
  // ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];
  messages:any = [];
  notifications: any = [];
  ngOnInit() {
    this.getMessageList();
    this.getNotificationList();
  }
  moveTo(path) {
    this.router.navigate([path]);
  }
  
  ngAfterViewInit() {}

   getMessageList() {
     this.sharedService.getMessageList({
       idAgent: this.currentUserInfo.id 
     }).subscribe(
       (res: any) => {
         console.log(res);
         this.messages = res;
       }
     )
   }

   getNotificationList() {
     this.sharedService.getNotificationList({
      idAgent: this.currentUserInfo.id
     }).subscribe(
       (res: any) => {
         console.log(res);
         this.notifications = res;
       }
     )
   }

   onMessageShow(row) {
    this.router.navigate(['/discussion/show/' + (this.isAgentIsSender(row) ? row.agent2.id : row.agent1.id) + '/' + row.id ]);
  }

  isAgentIsSender(discussion) {
    return Number(discussion.agent1.id) === Number(this.currentUserInfo.id);
  }

  setLang(lang) {
    lang === 'fr' ? this.lang = 'FR' : this.lang = 'EN';
    this.translateService.use(lang);
  }
  

}
