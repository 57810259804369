import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRightDirective } from '../shared/directives/has-right/has-right.directive';
import { SharedService } from './shared.service';
import {TranslateModule} from '@ngx-translate/core';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, RouterModule, TranslateModule],
    declarations: [
        HasRightDirective,
        BreadcrumbComponent
    ],
    providers: [
        SharedService
    ],
    exports: [
        HasRightDirective,
        BreadcrumbComponent
    ]
})

export class SharedModule { }
