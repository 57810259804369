﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Agent } from '../agent/model/agent.model';
import { AppConfig } from '../app-config';
import { Router } from '@angular/router';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { SERVER_URL } from '../app.constants';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<Agent>;
    profil;

    constructor(private http: HttpClient, private router: Router, private appConfig: AppConfig
    ) {
        this.currentUserSubject = new BehaviorSubject<Agent>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Agent {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>('http://' + SERVER_URL + '/login', { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user) {
                    // console.log("je suis la");
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('debutTravail', null);
                    localStorage.setItem('debutPause', null);
                    localStorage.setItem('finPause', null);
                    localStorage.setItem('finTravail', null);

                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('id', user.id);
                    localStorage.setItem('connected', "true");

                    if (user.profil) {
                        this.profil = true;
                        localStorage.setItem('profil', this.profil);

                    } else {
                        this.profil = false;
                        localStorage.setItem('profil', this.profil);
                    }

                    this.currentUserSubject.next(user);
                }
                // console.log("je suis dehors");

                return user;
            }));
    }

    loginAgent(data) {
        return this.http.post(this.appConfig.baseApiPath + 'loginAgent', data).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }


    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('id');
        localStorage.removeItem('profil');
        localStorage.setItem('connected','false');

        if (localStorage.getItem('debutTravail') === 'Ok'
        && localStorage.getItem('debutPause') === 'Ok'
        && localStorage.getItem('finPause') === 'Ok' 
        && localStorage.getItem('finTravail') === 'Ok') {

            localStorage.removeItem('debutTravail');
            localStorage.removeItem('debutPause');
            localStorage.removeItem('finPause');
            localStorage.removeItem('finTravail');

        }
        
        this.currentUserSubject.next(null);

    }
}