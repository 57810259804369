import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Agent } from 'src/app/agent/model/agent.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
declare var $: any;

import  { SharedService } from '../shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  currentUserInfo;
  profil;
  private currentUserSubject: BehaviorSubject<Agent>;
  public currentUser: any;
  public sidebarnavItems: any[];
  showImage = false;
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService ,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubject = new BehaviorSubject<Agent>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserInfo= JSON.parse(localStorage.getItem('currentUser'));
    // console.log(this.currentUserInfo);
  }

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(
      (sidebarnavItem) => {
        if (sidebarnavItem.rights) {
          return sidebarnavItem.rights.includes(this.currentUserInfo.droit.libDroit);
        } else {
          return sidebarnavItem
        }
      }).map(
        (sidemenufilterd) => {
          if (sidemenufilterd.submenu.length) {
            let submenuFiltered = sidemenufilterd.submenu.filter(
              (submenu) => {
                if (submenu.rights) {
                  return submenu.rights.includes(this.currentUserInfo.droit.libDroit);
                } else {
                  return submenu;
                }
              }
            );
              sidemenufilterd.submenu = submenuFiltered;
              return sidemenufilterd;
          } else {
            return sidemenufilterd;
          }
        });
    this.showProfil();
  }
  showProfil(){
    if(localStorage.getItem('profil')=="true"){
      this.profil=true;
    }else{
      this.profil=false;
    }
  }
  logout() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.sharedService.logout({idAgent: user.id}).subscribe(
      (res) => {
        this.authenticationService.logout();
        this.router.navigate(['/authentication/login']);
      }
    );
    //this.router.navigate(["/authentication/login"]);
  }
}
