import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {Observable} from 'rxjs/Observable';

@Directive({
  selector: '[appHasRight]'
})
export class HasRightDirective {
  currentUserInfo: any;
  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
      this.currentUserInfo=JSON.parse(localStorage.getItem('currentUser'));
     }

  @Input() set appHasRight(permissions: string[]) {

    const hasRight = permissions.includes(this.currentUserInfo.droit.libDroit);

    if (hasRight) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }

  }
}
