import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { AuthGuard } from './services/auth_guard.service.old';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [ AuthGuard ],
    
    children: [
      { path: '', redirectTo: '/home/dashboard', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: './home/home.module#HomeModule'
      },
      { path: 'product', loadChildren: './produit/produit.module#ProduitModule' },
      { path: 'pre-config', loadChildren: './pre-config/pre-config.module#PreconfigModule' },
      { path: 'boutique', loadChildren: './boutique/boutique.module#BoutiqueModule' },
      { path: 'agent', loadChildren: './agent/agent.module#AgentModule' },
      { path: 'client', loadChildren: './client/client.module#ClientModule' },
      { path: 'appro', loadChildren: './approvisionnement/appro.module#ApproModule' },
      { path: 'vente', loadChildren: './vente/vente.module#VenteModule' },
      { path: 'compta', loadChildren: './compta/compta.module#ComptaModule' },
      { path: 'inventaire', loadChildren: './inventaire/inventaire.module#InventaireModule' },
      { path: 'discussion', loadChildren: './discussion/discussion.module#DiscussionModule' },
      { path: 'depense', loadChildren: './depense/depense.module#DepenseModule' },
      { path: 'event', loadChildren: './event/event.module#EventModule' },
      { path: 'notification', loadChildren: './notification/notification.module#NotificationModule' },
      { path: 'salaires', loadChildren: './salaires/salaires.module#SalairesModule' },
      { path: 'horaires', loadChildren: './horaires/horaires.module#HorairesModule' },
      { path: 'repartition-interet', loadChildren: './repartition-interet/repartition-interet.module#RepartitionInteretModule' },
      { path: 'impots', loadChildren: './impot/impot.module#ImpotModule' },
      { path: 'promos', loadChildren: './promos/promos.module#PromosModule' },
      { path: 'reduction', loadChildren: './reduction/reduction.module#ReductionModule' },
      { path: 'shipping', loadChildren: './shipping/shipping.module#ShippingModule' },
      { path: 'amortizment', loadChildren: './amortizment/amortizment.module#AmortizmentModule' },
      { path: 'template', loadChildren: './template/template.module#TemplateModule' },
      { path: 'ressource', loadChildren: './ressource/ressource.module#RessourceModule' },
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
