import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  /* {
     path: '',
     title: 'Personal',
     icon: 'mdi mdi-dots-horizontal',
     class: 'nav-small-cap',
     extralink: true,
     submenu: []
   },*/
  {
    path: '/home/dashboard',
    title: 'Accueil',
    icon: 'fas fa-home',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur', 'superviseur', 'caisse']
  },
  {
    path: '/discussion',
    title: 'Messagerie',
    icon: 'fas fa-comments',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur', 'superviseur', 'caisse']
  },
  {
    path: '',
    title: 'Produit',
    icon: 'fab fa-product-hunt',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/product/list',
        title: 'Liste de produits',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur', 'superviseur', 'caisse']
      },
      {
        path: '/product/edit',
        title: 'Ajouter un produit',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },

    ]
  },
  {
    path: '',
    title: 'Boutique',
    icon: 'fab fa-app-store',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/boutique/list',
        title: 'Liste de boutiques',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/boutique/edit',
        title: 'Ajouter une boutique',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ],
    rights: ['administrateur']
  },
  /* {
     path: '',
     title: 'Personnel',
     icon: 'mdi mdi-dots-horizontal',
     class: 'nav-small-cap',
     extralink: true,
     submenu: [
 
      
     ]
   }, */
  {
    path: '',
    title: 'Agent',
    icon: 'fas fa-user',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/agent/list',
        title: 'Liste des agents',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/agent/edit',
        title: 'Ajouter un agent',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ],
    rights: ['administrateur']
  },
  {
    path: '',
    title: 'Client',
    icon: 'fas fa-user',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/client/list',
        title: 'Liste des clients',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/client/edit',
        title: 'Ajouter un client',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ],
    rights: ['administrateur']
  },
  {
    path: '',
    title: 'Approvisionnement',
    icon: 'fab fa-app-store',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/appro/add',
        title: 'Ajouter un appro',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/appro/list',
        title: 'Liste approvisionnement',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['superviseur', 'administrateur']
      }
    ],
    rights: ['superviseur', 'administrateur']
  },
  {
    path: '',
    title: 'Inventaire',
    icon: 'fab fa-app-store',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/inventaire/list',
        title: 'Liste',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/inventaire/create/manuelle',
        title: 'Inventaire manuel',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['superviseur', 'administrateur']
      },
      {
        path: '/inventaire/create/automatique',
        title: 'Inventaire automatisé',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['superviseur', 'administrateur']
      }
    ],
    rights: ['superviseur', 'administrateur']
  },
  {
    path: '/vente/home',
    title: 'Vente',
    icon: 'fas fa-shopping-cart',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur', 'superviseur', 'caisse']
  },
  {
    path: '',
    title: 'Configuration',
    icon: 'fas fa-cog',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/pre-config/gamme',
        title: 'Ajouter une gamme',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/pre-config/type',
        title: 'Ajouter un type produit',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/pre-config/category',
        title: 'Ajouter un type catégorie',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/pre-config/droit',
        title: 'Ajouter un droit',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/pre-config/droit/attribution',
        title: 'Attribuer un droit',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/pre-config/ressource',
        title: 'Ajouter une ressource',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '',
      //   title: 'Templates',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: [
      //     {
      //       path: '/pre-config/template',
      //       title: 'Liste',
      //       icon: '',
      //       class: '',
      //       extralink: false,
      //       submenu: []
      //     },
      //     {
      //       path: '/pre-config/template/add',
      //       title: 'Ajouter un template',
      //       icon: '',
      //       class: '',
      //       extralink: false,
      //       submenu: []
      //     }
      //   ]
      // }
    ],
    rights: ['administrateur']
  },
  {
    path: '',
    title: 'Salaires',
    icon: 'fas fa-dollar-sign',
    class: 'has-arrow',
    extralink: false,
    rights: ['administrateur'],
    submenu: [
      {
        path: '/salaires/type',
        title: 'Type salaire',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/salaires/payer',
        title: 'Payer salaire',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/salaires/supervises',
        title: 'Salaires supervisés',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      }
    ]
  },
  {
    path: '/depense',
    title: 'Dépense',
    icon: 'fas fa-dollar-sign',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur', 'superviseur', 'caisse']
  },
  {
    path: '/compta/ventes',
    title: 'Comptabilité',
    icon: 'fas fa-clipboard-list',
    class: '',
    extralink: false,
    submenu: [
    ],
    rights: ['administrateur']
  },
  {
    path: '/event',
    title: 'Historique',
    icon: 'fas fa-history',
    class: '',
    extralink: false,
    submenu: [
    ],
    rights: ['administrateur']
  },
  {
    path: '/horaires',
    title: 'Horaires',
    icon: 'fas fa-history',
    class: '',
    extralink: false,
    submenu: [
    ],
    rights: ['administrateur']
  },
  {
    path: '',
    title: 'Repartition interêt',
    icon: 'fas fa-dollar-sign',
    class: 'has-arrow',
    extralink: false,
    rights: ['administrateur'],
    submenu: [
      {
        path: '/repartition-interet/associes',
        title: 'Associés',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/repartition-interet/retrait',
        title: 'Retrait',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur', 'superviseur']
      },
      {
        path: '/repartition-interet/retrait-supervises',
        title: 'Retrait supervisés',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur', 'superviseur']
      }
    ]
  },
  {
    path: '',
    title: 'Impôts',
    icon: 'fas fa-dollar-sign',
    class: 'has-arrow',
    extralink: false,
    rights: ['administrateur'],
    submenu: [
      {
        path: '/impots/payment',
        title: 'Payement',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/impots/report-form',
        title: 'Rapport',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      }
    ]
  },
  {
    path: '/promos',
    title: 'Promotion',
    icon: 'fas fa-dollar-sign',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur']
  },
  {
    path: '/reduction',
    title: 'Réduction',
    icon: 'fas fa-dollar-sign',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur']
  },
  {
    path: '',
    title: 'Templates',
    icon: 'fas fa-file-code',
    class: 'has-arrow',
    extralink: false,
    rights: ['administrateur'],
    submenu: [
      {
        path: '/template/',
        title: 'Liste',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/template/add',
        title: 'Ajouter',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/template/send-email',
        title: 'Envoyer emails',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      },
      {
        path: '/template/print-flyers',
        title: 'Imprimer flyers',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
        rights: ['administrateur']
      }
    ]
  },
  {
    path: '/amortizment',
    title: 'Amortissement',
    icon: 'fas fa-dollar-sign',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur']
  },
  {
    path: '/shipping',
    title: 'Transport',
    icon: 'fas fa-truck',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur']
  },
  {
    path: '/ressource',
    title: 'Ressource',
    icon: 'fab fa-product-hunt',
    class: '',
    extralink: false,
    submenu: [],
    rights: ['administrateur']
  },
];
