
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppConfig } from '../app-config';

@Injectable()
export class SharedService {
    constructor(private http: HttpClient, private appConfig: AppConfig) {
    }

   getMessageList(params) {
        return this.http.get(this.appConfig.baseApiPath + 'disc/list', {
            params: params
        }).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }
    
    getNotificationList(params) {
        return this.http.get(this.appConfig.baseApiPath + 'notifications/unreaded', {
            params: params
        }).map(
                (response: HttpResponse<any>) => {
                return response;
                }
            ).catch(
                (error: HttpErrorResponse) => {
                return Observable.throw({
                    code: error.status,
                    content: JSON.parse(error.error).message
                });
                }
            );
    }

    logout(data) {
        return this.http.post(this.appConfig.baseApiPath + 'logout', data).map(
            (response: HttpResponse<any>) => {
            return response;
            }
        ).catch(
            (error: HttpErrorResponse) => {
            return Observable.throw({
                code: error.status,
                content: JSON.parse(error.error).message
            });
            }
        );
    }

}  